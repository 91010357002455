<template>
    <div class="Home">
        <page-header/>

        <section class="uk-section uk-padding-remove">
            <div class="uk-container">
                <div class="uk-grid" uk-grid>
                    <div class="uk-width-1-5@m">
                    </div>
                    <div class="uk-width-3-5@m">
                        <section class="uk-section uk-padding-remove">
                            <div class="uk-container">
                                <div class="uk-width-1-1@m">
                                    <img src="https://picsum.photos/1200/250/?random" alt="" >
                                </div>
                            </div>
                        </section>
                        <section class="uk-section uk-padding-remove-top uk-margin-top">
                            <div class="uk-container">
                                <div class="uk-width-1-1@m" v-for="(item, index) in organizs" :key="index">
                                    <div v-if="$route.params.organizId == item.organizId">
                                      <div class="uk-subnav">
                                        <h2 class="title uk-text-capitalize" v-html="item.organizName"></h2>
                                        <h3 class="uk-margin-small">{{item.organizType}}</h3>
                                      </div>
                                      <h5 class="uk-margin">
                                        Description: {{item.description}}
                                      </h5>
                                      <div class="uk-margin-medium-top">
                                        <ul class="uk-subnav uk-subnav-pill uk-flex-center" uk-switcher>
                                          <li class="uk-active" ><a href="#">Free Exams</a></li>
                                          <li><a href="#" >Paid Exams</a></li>
                                        </ul>
                                      </div>
                                      <ul class="uk-grid-column-small uk-switcher uk-margin uk-grid-row-large uk-child-width-1-3@s uk-text-center" uk-grid>
                                        <!-- <li v-for="(item, index) in organizs" :key="index" uk-switcher-item="0">
                                            <div class="uk-card uk-card-hover uk-width-1-1@m">
                                                <div class="uk-card-header">
                                                    <div class="uk-width-auto">
                                                        <img class="uk-border-circle" width="50" height="40" src="../../assets/schoolLogo/STT GARUT.png">
                                                    </div>
                                                    <div class="uk-grid-small uk-flex-middle" uk-grid>
                                                        <div class="uk-width-expand">
                                                            <h4 class="uk-margin-remove-bottom">{{item.organizId}}</h4>
                                                            <p class="uk-text-meta uk-margin-remove-top">April 01, 2016</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="uk-card-footer">
                                                    <a href="#" v-on:click="SRequest(item.organizId)" class="uk-button uk-button-text">Register</a>
                                                </div>
                                            </div>
                                        </li> -->
                                        <!-- <li v-for="(item, index) in latestexams" :key="index" uk-switcher-item="1">
                                            <div class="uk-card uk-card-hover uk-width-1-1@m">
                                                <div class="uk-card-header">
                                                    <div class="uk-width-auto">
                                                        <img class="uk-border-circle" width="50" height="40" src="../../assets/schoolLogo/STT GARUT.png">
                                                    </div>
                                                    <div class="uk-grid-small uk-flex-middle" uk-grid>
                                                        <div class="uk-width-expand">
                                                            <h4 class="uk-margin-remove-bottom">{{item.examName}}</h4>
                                                            <p class="uk-text-meta uk-margin-remove-top">{{item.examSDate}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="uk-card-footer">
                                                    <a href="#" v-on:click="SRequest(item.organizId)" class="uk-button uk-button-text">Register</a>
                                                </div>
                                            </div>
                                        </li>  -->
                                      </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="uk-width-1-5@m">

                    </div>
                </div>
            </div>
        </section>
        <page-footer/>
    </div>

</template>
<script>
import StudentService from '@/services/StudentService'
const PageHeader = () => import("./StudentHeaderNew.vue");
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
export default {
    name: 'Home',
    components: {
        PageHeader,
        PageFooter
    },
    data(){
    return {
        organizs: [],
        allExamList: [],
    }
    },
    created() {
        this.StudentHomeData()
    },
    methods: {
      async StudentHomeData(){
         try {
          const response = await StudentService.StudentHomeData({})
            if (response.data.status == 200) {
              this.organizs = response.data.result.organizs
            }
        } catch (error) {
          console.log(error)
        }
      },
      async AllExamList() {
        try {
          const response = await StudentService.AllExamList({
            examName: this.inputval
          })
          this.allExamList = response.data.content
          console.log("AllExamList")
          console.log(response.data)
        } catch (error) {
          console.log(error)
        }
      },
    }
}
</script>
<style scoped>
.title{
    text-transform: capitalize;
    font-weight: 600;
}

</style>
